

import {
  IonButton,
  IonCol, IonGrid,
  IonIcon,
  IonItem, IonItemDivider, IonItemOption,
  IonItemOptions, IonItemSliding,
  IonLabel,
  IonRow,
  isPlatform,
  modalController, onIonViewWillEnter
} from "@ionic/vue";
import {computed, defineComponent, ref} from "vue";
import {useRouter} from "vue-router";
import UserDetailPage from "@/app/view/user/detail/UserDetailPage.vue";
import {useUserApi} from "@/domain/service/api/use-user-api";
import {User} from "@/domain/model/user/user.model";
import router from "@/app/router";

export default defineComponent({
  name: "UserListComponent",
  components: {
    IonGrid,
    IonItem,
    IonLabel,
    IonCol,
    IonRow,
    IonButton,
    IonIcon,
    IonItemOptions,
    IonItemOption,
    IonItemSliding,
  },

  props: {
    user: null,
  },

  setup(prop: any) {
    const router = useRouter();
    const userRef = computed<User>(() => prop.user);
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const id = userRef.value.id;
    const {removeUser} = useUserApi();
    const onEditUser = async () => {
      const modal = await modalController
          .create({
            component: UserDetailPage,
            componentProps: {id},
          });
      return modal.present();
    };


    const onGoUpdateUserDetails = async () => {
      await router.push({
        name: 'UserProfileUpdate',
        params: {id: userRef.value.id}
      });
    }
    const onRemoveUser = async () => {
      await removeUser(userRef.value);
    };
    const charge = computed(() => {
      if (userRef.value.charge === 'employee_stock')
        return 'Operario';
      else if (userRef.value.charge === 'ceo')
        return 'Directivo';
      else if (userRef.value.charge === 'employee_production')
        return 'Empleado';
      else if (userRef.value.charge === 'manager_production')
        return 'Jefe de planta';
      else
        return 'Recursos Humanos';

    });
    const shift = computed(() => {
      if (userRef.value.shift === 'morning')
        return 'Mañana';
      else if (userRef.value.shift === 'afternoon')
        return 'Tarde';
      else if (userRef.value.shift === 'night')
        return 'Noche';
      else if (userRef.value.shift === 'central')
        return 'Central';
      else if (userRef.value.shift === 'sick_leave')
        return 'Baja Médica';
      else
        return 'Sin especificar';
    })

    onIonViewWillEnter(() => {
      useUserApi().fetchAll();
    })

    return {
      isTablet,
      router,
      userRef,
      charge,
      onEditUser,
      onRemoveUser,
      id,
      onGoUpdateUserDetails,
      shift,
    };
  }
})
