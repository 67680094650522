
import {uuid4} from "@capacitor/core/dist/esm/util";
import {
  alertController,
  IonButton,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonPage, IonSelect, IonSelectOption,
  IonTitle,
  IonToolbar,
  modalController,
} from "@ionic/vue";
import {computed, defineComponent, ref, watch} from "vue";
import {initialState} from "@/domain/store/viewState/view-state.module";
import {useUserApi} from "@/domain/service/api/use-user-api";
import {
  CreateUser,
  updateUserByEntity,
  UpdateUserWithProfileData,
  UpdateUserWithProfileDataFromUser
} from "@/domain/command/user/user.command";
import {useStore} from "vuex";
import _ from "lodash";
import {User} from "@/domain/model/user/user.model";
import {
  FormInst, FormItemInst,
  FormItemRule,
  FormRules,
  FormValidationError,
  NButton,
  NCol,
  NForm,
  NFormItem,
  NInput, NInputNumber, NMessageProvider,
  NRow, NSelect, useMessage
} from 'naive-ui';
import {loading} from '@/app/service/ionic/loading';

export default defineComponent({
  name: "UserCreatePage",
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    // IonItem,
    // IonInput,
    // IonLabel,
    // IonButton,
    // IonSelect,
    // IonSelectOption,
    NForm,
    NFormItem,
    NInput,
    NCol,
    NRow,
    NButton,
    NSelect,
    NInputNumber,
  },
  props: {
    employees: Boolean,
  },
  setup(props: any) {
    const {createUser} = useUserApi();
    const store = useStore();
    const isSubmitting = ref(false);
    const command = ref<CreateUser>({...initialState.createUserInList});
    const formRef = ref<FormInst | null>(null);
    const rPasswordFormItemRef = ref<FormItemInst | null>(null);
    // const message = useMessage();
    const modelRef = ref<CreateUser>({
      id: uuid4(),
      username: '',
      email: '',
      password: '',
      charge: '',
      shift: 'central',
      workerNumber: 0,
    });
    const reenteredPassword = ref<string>('');

    const validatePasswordStartWith = (
        rule: FormItemRule,
        value: string
    ): boolean => {
      return (
          !!modelRef.value.password &&
          modelRef.value.password.startsWith(value) &&
          modelRef.value.password.length >= value.length
      )
    }

    function validatePasswordSame(rule: FormItemRule, value: string): boolean {
      return value === modelRef.value.password
    }

    const allUsersUsername = computed<string[]>(() => store.getters.allUsers.map((userNode: User) => userNode.username));
    const rules: FormRules = {
      username: [
        {
          required: true,
          validator(rule: FormItemRule, value: string) {
            if (!value) {
              return new Error('Introduce un nombre de usuario')
            } else if (allUsersUsername.value.indexOf(value) !== -1) {
              return new Error('Ya existe otro usuario con ese nombre')
            }
            return true
          },
          trigger: ['input', 'blur']
        }
      ],
      password: [
        {
          required: true,
          message: 'Introduce una contraseña'
        }
      ],
      reenteredPassword: [
        {
          required: !props.employees,
          message: 'Confirma la contraseña',
          trigger: ['input', 'blur']
        },
        {
          validator: validatePasswordStartWith,
          message: 'Las contraseñas no coinciden',
          trigger: 'input'
        },
        {
          validator: validatePasswordSame,
          message: 'Las contraseñas no coinciden',
          trigger: ['blur', 'password-input']
        }
      ],
      charge: [
        {
          required: true,
          message: 'Elige el cargo',
          trigger: ['input', 'blur']
        }
      ],
      shift: [
        {
          required: true,
          message: 'Elige el turno',
          trigger: ['input', 'blur']
        }
      ],
      workerNumber: [
        {
          message: 'Elige el número del empleado',
          required: true,
        }
      ],
    }
    const submit = async () => {
      command.value.id = uuid4();
      await loading().exec('Creando...', async () => {
        if (isSubmitting.value)
          return;

        isSubmitting.value = true;

        await createUser(modelRef.value);
        if (props.employees){
          const updateCommand: UpdateUserWithProfileData = UpdateUserWithProfileDataFromUser(store.getters.userById(modelRef.value.id))
          updateCommand.name = modelRef.value.username.sub()
        }
        isSubmitting.value = false;

        await modalController.dismiss();
      })
    };

    return {
      formRef,
      rPasswordFormItemRef,
      command,
      isSubmitting,
      submit,
      model: modelRef,
      rules,
      handlePasswordInput() {
        if (reenteredPassword.value) {
          rPasswordFormItemRef.value?.validate({trigger: 'password-input'})
        }
      },
      handleValidateButtonClick(e: MouseEvent) {
        e.preventDefault()
        formRef.value?.validate(
            (errors: Array<FormValidationError> | undefined) => {
              if (!errors) {
                submit()
              } else {
                console.log(errors);

              }
            }
        )
      }
    };
  }
})
