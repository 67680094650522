

import {computed, ref, defineComponent, watch} from "vue";
import {
  alertController,
  IonButton,
  IonContent, IonHeader, IonInput,
  IonItem, IonLabel,
  IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar, modalController,
} from "@ionic/vue";

import {useStore} from "vuex";
import {User} from "@/domain/model/user/user.model";
import {useUserApi} from "@/domain/service/api/use-user-api";
import {initialState} from "@/domain/store/viewState/view-state.module";
import {UpdateUser, updateUserByEntity} from "@/domain/command/user/user.command";
import _ from "lodash";

export default defineComponent({
  name: "UserDetailPage",
  components: {
    IonPage,
    IonItem,
    IonContent,
    IonTitle,
    IonHeader,
    IonToolbar,
    IonLabel,
    IonInput,
    IonButton,
    IonSelect,
    IonSelectOption,
  },

  props: {
    id: null,
  },

  setup(props: any) {
    const id = ref<string>(props.id);
    const store = useStore();
    const user = computed<User>(() => store.getters.userById(id.value));
    const command = ref<UpdateUser>(updateUserByEntity(user.value));
    const {updateUser} = useUserApi();
    const closeModal = async () => {
      return modalController.dismiss();
    };
    const onFormError = async () => {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Datos incorrectos!',
            message: 'El email o el usuario ya existen, introduce otros distintos',
            buttons: [
              {
                text: 'Aceptar',
                role: 'confirm',
                cssClass: 'secondary',
                handler: () => {
                  closeModal();
                },
              },
            ],
          });
      await alert.present();

      const {role} = await alert.onDidDismiss();
    }
    const onUpdateUser = async () => {
      const user: User = store.getters.userById(command.value.id);
      if (user.email && command.value.email)
        if (command.value.email.toLowerCase() !== user.email.toLowerCase())
          if (_.includes(store.getters.allUsers.map((user: User) => {
            if(!user.email)
              return
            return user.email.toLowerCase();
          }), command.value.email.toLowerCase())) {
            await onFormError();
            return;
          }
      if (command.value.username.toLowerCase() !== user.username.toLowerCase())
        if (_.includes(store.getters.allUsers.map((user: User) => user.username.toLowerCase()), command.value.username.toLowerCase()) || !command.value.username) {
          await onFormError();
          return;
        }

      await updateUser(command.value);
      await modalController.dismiss();
    };

    watch(command, () => console.log(command.value));

    return {
      user,
      command,
      onUpdateUser,
    };


  }
})
